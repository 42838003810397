* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.triangleBottomleft {
  width: 0;
  height: 0;
  border-bottom: 50vh solid #495464;
  border-right: 100vw solid transparent;
  position: relative;
}

.profileHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #EEEEEE;
}
.profileHeader .profile {
  width: 100vw;
  height: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.profileHeader .profile .imageProfileContainer {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin-left: 2px;
  z-index: 0;
}
.profileHeader .profile .imageProfileContainer .imageProfile {
  width: 60%;
  border-radius: 50%;
  z-index: 10;
  position: relative;
}
.profileHeader .profile .imageProfileContainer::before {
  content: "";
  position: absolute;
  width: 50vw;
  height: 30%;
  background: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  -webkit-animation: animate 2s linear infinite;
          animation: animate 2s linear infinite;
}
.profileHeader .profile .imageProfileContainer::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #495464;
  border-radius: 16px;
}
@-webkit-keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.profileHeader .profile .personalInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.profileHeader .profile .personalInfo .personalData {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}
.profileHeader .profile .personalInfo .personalData .Firstname {
  width: 37.5%;
  font-size: 3.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
  position: relative;
  -webkit-text-stroke: 0.3vw #383d52;
  font-weight: bold;
}
.profileHeader .profile .personalInfo .personalData .Firstname::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  align-items: center;
  color: #F1F1F1;
  -webkit-text-stroke: 0vw #383d52;
  overflow: hidden;
  -webkit-animation: name 6s linear infinite;
          animation: name 6s linear infinite;
}
.profileHeader .profile .personalInfo .personalData .Lastname {
  width: 14%;
  font-size: 3.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
  position: relative;
  -webkit-text-stroke: 0.3vw #383d52;
  font-weight: bold;
}
.profileHeader .profile .personalInfo .personalData .Lastname::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  color: #F1F1F1;
  -webkit-text-stroke: 0vw #383d52;
  overflow: hidden;
  -webkit-animation: name 6s linear infinite;
          animation: name 6s linear infinite;
}
@-webkit-keyframes name {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}
@keyframes name {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}
.profileHeader .profile .personalInfo .personalData .quote {
  width: 80%;
  margin-top: 10px;
  font-size: 2vw;
  font-family: "DynaPuff", cursive;
  color: white;
}
.profileHeader .profile .personalInfo .position {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.profileHeader .profile .personalInfo .position .positionText {
  width: 100%;
  height: 20%;
  font-family: "Titillium Web", sans-serif;
  color: #495464;
  text-align: center;
  font-size: 2vw;
}
.profileHeader .profile .personalInfo .position .contact {
  width: 70%;
  margin: 0 auto;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.profileHeader .profile .personalInfo .position .contact .link {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
}
.profileHeader .profile .personalInfo .position .contact .link .icon {
  font-size: 1.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
}
.profileHeader .profile .personalInfo .position .contact .link .linkText {
  font-size: 1.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
}

@media screen and (max-width: 480px) {
  .triangleBottomleft {
    border-bottom: 25vh solid #495464;
    border-right: 100vw solid transparent;
    position: relative;
  }
  .profileHeader {
    width: 100vw;
    height: 25vh;
  }
  .profileHeader .profile {
    width: 100vw;
    height: 17%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .profileHeader .profile .imageProfileContainer {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    margin-left: 2vw;
    z-index: 0;
  }
  .profileHeader .profile .imageProfileContainer .imageProfile {
    width: 80%;
  }
  .profileHeader .profile .imageProfileContainer::after {
    inset: 2px;
  }
  .profileHeader .profile .personalInfo {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .personalData {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .profileHeader .profile .personalInfo .personalData .Firstname {
    width: 40%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .Lastname {
    width: 14%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .quote {
    margin-top: 10px;
    font-size: 2vw;
    font-family: "DynaPuff", cursive;
    color: white;
  }
  .profileHeader .profile .personalInfo .position {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .position .positionText {
    width: 100%;
    height: 50%;
    font-family: "Titillium Web", sans-serif;
    color: #495464;
    text-align: center;
    font-size: 2vw;
  }
  .profileHeader .profile .personalInfo .position .contact {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }
  .profileHeader .profile .personalInfo .position .contact .link {
    width: 15%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .profileHeader .profile .personalInfo .position .contact .link .icon {
    font-size: 2vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileHeader .profile .personalInfo .position .contact .link .linkText {
    display: none;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .triangleBottomleft {
    border-bottom: 40vh solid #495464;
    border-right: 100vw solid transparent;
    position: relative;
  }
  .profileHeader {
    width: 100vw;
    height: 40vh;
  }
  .profileHeader .profile {
    width: 100vw;
    height: 25vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .profileHeader .profile .imageProfileContainer {
    width: 15%;
    height: 50%;
  }
  .profileHeader .profile .imageProfileContainer .imageProfile {
    width: 80%;
  }
  .profileHeader .profile .imageProfileContainer::before {
    width: 180px;
  }
  .profileHeader .profile .personalInfo {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .personalData {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .profileHeader .profile .personalInfo .personalData .Firstname {
    width: 38.5%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .Lastname {
    width: 14%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .quote {
    margin-top: 10px;
    font-size: 1.5vw;
    font-family: "DynaPuff", cursive;
    color: white;
  }
  .profileHeader .profile .personalInfo .position {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .position .positionText {
    width: 100%;
    height: 20%;
    font-family: "Titillium Web", sans-serif;
    color: #495464;
    text-align: center;
    font-size: 2vw;
  }
  .profileHeader .profile .personalInfo .position .contact {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }
  .profileHeader .profile .personalInfo .position .contact .link {
    width: 15%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .profileHeader .profile .personalInfo .position .contact .link .icon {
    font-size: 2vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileHeader .profile .personalInfo .position .contact .link .linkText {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .triangleBottomleft {
    border-bottom: 30vh solid #495464;
    border-right: 100vw solid transparent;
    position: relative;
  }
  .profileHeader {
    width: 100vw;
    height: 30vh;
  }
  .profileHeader .profile {
    width: 100vw;
    height: 25vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .profileHeader .profile .imageProfileContainer {
    height: 50%;
  }
  .profileHeader .profile .imageProfileContainer .imageProfile {
    width: 60%;
  }
  .profileHeader .profile .imageProfileContainer::before {
    width: 250px;
  }
  .profileHeader .profile .personalInfo {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .personalData {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profileHeader .profile .personalInfo .personalData .Firstname {
    width: 38%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .Lastname {
    width: 14%;
    font-size: 3.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
    position: relative;
    -webkit-text-stroke: 0.3vw #383d52;
    font-weight: bold;
  }
  .profileHeader .profile .personalInfo .personalData .quote {
    margin-top: 10px;
    font-size: 1.5vw;
    font-family: "DynaPuff", cursive;
    color: white;
  }
  .profileHeader .profile .personalInfo .position {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  .profileHeader .profile .personalInfo .position .positionText {
    width: 100%;
    height: 20%;
    font-family: "Titillium Web", sans-serif;
    color: #495464;
    text-align: center;
    font-size: 2vw;
  }
  .profileHeader .profile .personalInfo .position .contact {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
  }
  .profileHeader .profile .personalInfo .position .contact .link {
    width: 15%;
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .profileHeader .profile .personalInfo .position .contact .link .icon {
    font-size: 2vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileHeader .profile .personalInfo .position .contact .link .linkText {
    display: none;
  }
}/*# sourceMappingURL=header.module.css.map */