.container{
    width: 100vw;
    height: 100vh;
    background-color: #16213E;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .loadingContainer{
        width: 60vw;
        height: 4vh;
        margin: 0 auto;
        border-radius: 2vw;
        border: 2px solid #EEEEEE;
        position: relative;

        .loading{
            height: 100%;
            width: 0;
            margin: 0 auto;
            background-color: #7FB77E;
            border-radius: 2vw;
            animation: loading 1.5s linear 1 forwards;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .loading::after{
            content: "LOADING...";
            color: #EEEEEE;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1vw;
            height: 100%;
            // width: 100%;
            overflow: hidden;
        }

        @keyframes loading {
            0%{
                width: 0;
            }
            30%{
                width: 30%;
            }
            50%{
                width: 50%;
            }
            70%{
                width: 70%;
            }
            100%{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .container{
        
        .loadingContainer{
            width: 70vw;
            border-radius: 5vw;
            
            .loading{
                border-radius: 5vw;
            }
    
            .loading::after{
                font-size: 2.5vw;
            }
        }
    }
}

@media screen and (min-width: 540px) and (max-width: 780px){
    .container{
        
        .loadingContainer{
            width: 70vw;
            border-radius: 5vw;
    
            .loading{
                border-radius: 5vw;
            }
    
            .loading::after{
                font-size: 2.5vw;
            }
        }
    }
}
