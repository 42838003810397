.container {
  width: 100vw;
  height: 8vh;
  box-shadow: 0px 0.5px 0px 0px rgb(184, 181, 181);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #EEEEEE;
  z-index: 99;
}
.container .linkContainer {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
}
.container .linkContainer .linkActiveBg {
  width: 8vw;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  transition: 0.2s ease-in-out;
}
.container .linkContainer .linkActiveBg .activeLink {
  font-size: 2.2vw;
  color: #0078AA;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .linkContainer .linkActiveBg .inactiveLink {
  font-size: 2.2vw;
  color: #495464;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .container {
    margin-top: 1vh;
    width: 100vw;
    box-shadow: 0px 0.5px 0px 0px rgb(184, 181, 181);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #EEEEEE;
    z-index: 99;
  }
  .container .linkContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg {
    width: 10vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg .activeLink {
    font-size: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg .inactiveLink {
    font-size: 3.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .container .linkContainer .linkActiveBg {
    width: 10vw;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg .activeLink {
    font-size: 3vw;
  }
  .container .linkContainer .linkActiveBg .inactiveLink {
    font-size: 3vw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg {
    width: 10vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container .linkContainer .linkActiveBg .activeLink {
    font-size: 3vw;
  }
  .container .linkContainer .linkActiveBg .inactiveLink {
    font-size: 3vw;
  }
}/*# sourceMappingURL=navigation.module.css.map */