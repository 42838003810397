.profileFooter {
  width: 100%;
  height: 10%;
  background-color: #495464;
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profileFooter .logoContainer {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.profileFooter .logoContainer .logo {
  width: 25%;
  border-radius: 50%;
}
.profileFooter .logoContainer .logoTextContainer {
  padding: 1vw;
  background-color: #1CD6CE;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
}
.profileFooter .logoContainer .logoTextContainer .logoText {
  color: #F7F7F7;
  font-size: 2vw;
  font-weight: bold;
}
.profileFooter .addressContainer {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.profileFooter .addressContainer .addressRow {
  width: 100%;
  height: 15%;
  font-size: 1.5vw;
  color: #EEEEEE;
  display: flex;
  align-items: center;
}
.profileFooter .addressContainer .addressRow .country {
  width: 2vw;
}
.profileFooter .detailInfo {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.profileFooter .detailInfo .bigName {
  width: 100%;
  height: 50%;
  font-size: 3vw;
  font-family: "Dancing Script", cursive;
  color: #EEEEEE;
  text-align: center;
}
.profileFooter .detailInfo .copyRight {
  width: 100%;
  height: 50%;
  font-size: 1vw;
  font-family: "Titillium Web", sans-serif;
  color: #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileFooter .detailInfo .copyRight .copyRightIcon {
  font-size: 1vw;
}

@media screen and (max-width: 480px) {
  .profileFooter {
    width: 100%;
    height: 10%;
    background-color: #495464;
    padding: 20px;
  }
  .profileFooter .logoContainer .logo {
    width: 40%;
  }
  .profileFooter .logoContainer .logoTextContainer {
    margin-left: 1em;
  }
  .profileFooter .logoContainer .logoTextContainer .logoText {
    font-size: 2.5vw;
  }
  .profileFooter .detailInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .profileFooter .detailInfo .bigName {
    width: 100%;
    height: 25%;
    font-size: 5vw;
    font-family: "Dancing Script", cursive;
    color: #EEEEEE;
  }
  .profileFooter .detailInfo .copyRight {
    width: 100%;
    height: 25%;
    font-size: 2vw;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileFooter .detailInfo .copyRight .copyRightIcon {
    font-size: 2vw;
  }
  .profileFooter .toast {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .profileFooter {
    height: 30vh;
    padding: 10px;
  }
  .profileFooter .logoContainer {
    width: 33%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none;
  }
  .profileFooter .logoContainer .logo {
    width: 30%;
  }
  .profileFooter .logoContainer .logoTextContainer {
    padding: 1vw;
    background-color: #1CD6CE;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1vw;
  }
  .profileFooter .logoContainer .logoTextContainer .logoText {
    color: #F7F7F7;
    font-size: 2vw;
    font-weight: bold;
  }
  .profileFooter .addressContainer {
    width: 33%;
    height: 100%;
  }
  .profileFooter .addressContainer .addressRow {
    width: 100%;
    height: 20%;
    font-size: 2vw;
    color: #EEEEEE;
  }
  .profileFooter .addressContainer .addressRow .country {
    width: 2vw;
  }
  .profileFooter .detailInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profileFooter .detailInfo .bigName {
    width: 100%;
    height: 25%;
    font-size: 3vw;
    font-family: "Dancing Script", cursive;
    color: #EEEEEE;
    text-align: center;
  }
  .profileFooter .detailInfo .copyRight {
    width: 100%;
    height: 25%;
    font-size: 1.5vw;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileFooter .detailInfo .copyRight .copyRightIcon {
    font-size: 1vw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .profileFooter {
    height: 30vh;
    padding: 10px;
  }
  .profileFooter .addressContainer .addressRow {
    font-size: 2vw;
  }
  .profileFooter .addressContainer .addressRow .country {
    width: 2vw;
  }
}/*# sourceMappingURL=footer.module.css.map */