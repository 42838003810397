.container {
  width: 100%;
  height: 100%;
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
}
.container .navigation {
  width: 100%;
  height: 8vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #EEEEEE;
}
.container .navigation .link {
  font-size: 2vw;
  text-decoration: none;
  color: #495464;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.container .navigation .activeLink {
  font-size: 2vw;
  text-decoration: none;
  color: #495464;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  border-bottom: 0.5px solid gray;
  transition: 0.05s ease-in-out;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.container .content {
  margin-top: 2vh;
  width: 100%;
  height: 90%;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
    background-color: #EEEEEE;
    display: flex;
    flex-direction: column;
  }
  .container .navigation {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #EEEEEE;
  }
  .container .navigation .link {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
  }
  .container .navigation .activeLink {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    border-bottom: 0.5px solid gray;
    transition: 0.05s ease-in-out;
    cursor: pointer;
  }
  .container .content {
    margin-top: 2vh;
    width: 100vw;
    height: 50vh;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .container {
    width: 100%;
    height: 100vh;
    background-color: #EEEEEE;
  }
  .container .navigation {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #EEEEEE;
  }
  .container .navigation .link {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
  }
  .container .navigation .activeLink {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    border-bottom: 0.5px solid gray;
    transition: 0.05s ease-in-out;
    cursor: pointer;
  }
  .container .content {
    margin-top: 2vh;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .container {
    width: 100%;
    height: 100vh;
    background-color: #EEEEEE;
  }
  .container .navigation {
    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #EEEEEE;
  }
  .container .navigation .link {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
  }
  .container .navigation .activeLink {
    font-size: 2vw;
    text-decoration: none;
    color: #495464;
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
    border-bottom: 0.5px solid gray;
    transition: 0.05s ease-in-out;
    cursor: pointer;
  }
  .container .content {
    margin-top: 2vh;
    width: 100%;
    height: 100%;
  }
}/*# sourceMappingURL=class.module.css.map */