.profileFooter{
    width: 100%;
    height: 10%;
    background-color: #495464;
    padding: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logoContainer{
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        -webkit-user-select:none;
        -moz-user-select:none;
        user-select:none;

        .logo{
            width: 25%;
            // width: 40%;
            // border-radius: 5em;
            border-radius: 50%;
        }
        
        .logoTextContainer{
            // padding: 0.5vw;
            padding: 1vw;
            background-color: #1CD6CE;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1vw;

            .logoText{
                color: #F7F7F7;
                font-size: 2vw;
                font-weight: bold;
            }
        }
    }
    .addressContainer{
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .addressRow{
            width: 100%;
            height: 15%;
            font-size: 1.5vw;
            color: #EEEEEE;
            display: flex;
            align-items: center;

            .country{
                width: 2vw;
            }
        }
    }
    .detailInfo{
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        -webkit-user-select:none;
        -moz-user-select:none;
        user-select:none;

        .bigName{
            width: 100%;
            height: 50%;
            font-size: 3vw;
            font-family: 'Dancing Script', cursive;
            color: #EEEEEE;
            text-align: center;
        }

        .copyRight{
            width: 100%;
            height: 50%;
            font-size: 1vw;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            display: flex;
            justify-content: center;
            align-items: center;

            .copyRightIcon{
                font-size: 1vw;
            }
        }
    }
}
@media screen and (max-width: 480px) {

    .profileFooter{
        width: 100%;
        height: 10%;
        background-color: #495464;
        padding: 20px;

        .logoContainer{
    
            .logo{
                width: 40%;
            }
            
            .logoTextContainer{
                margin-left: 1em;
    
                .logoText{
                    font-size: 2.5vw;
                }
            }
        }

        .detailInfo{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .bigName{
                width: 100%;
                height: 25%;
                font-size: 5vw;
                font-family: 'Dancing Script', cursive;
                color: #EEEEEE;
            }

            .copyRight{
                width: 100%;
                height: 25%;
                font-size: 2vw;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                justify-content: center;
                align-items: center;

                .copyRightIcon{
                    font-size: 2vw;
                }
            }
        }

        .toast{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2vw;
        }
    }
}
@media screen and (min-width: 540px) and (max-width: 780px){
    .profileFooter{
        height: 30vh;
        padding: 10px;
        .logoContainer{
            width: 33%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            -webkit-user-select:none;
            -moz-user-select:none;
            user-select:none;
    
            .logo{
                width: 30%;
            }
            
            .logoTextContainer{
                padding: 1vw;
                background-color: #1CD6CE;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 1vw;
    
                .logoText{
                    color: #F7F7F7;
                    font-size: 2vw;
                    font-weight: bold;
                }
            }
        }
        .addressContainer{
            width: 33%;
            height: 100%;

    
            .addressRow{
                width: 100%;
                height: 20%;
                font-size: 2vw;
                color: #EEEEEE;
                // display: flex;
                // align-items: center;
                // justify-content: space-evenly;
    
                .country{
                    width: 2vw;
                }
            }
        }
        .detailInfo{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .bigName{
                width: 100%;
                height: 25%;
                font-size: 3vw;
                font-family: 'Dancing Script', cursive;
                color: #EEEEEE;
                text-align: center;
            }
    
            .copyRight{
                width: 100%;
                height: 25%;
                font-size: 1.5vw;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .copyRightIcon{
                    font-size: 1vw;
                }
            }
        }
    }
}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){
    .profileFooter{
        height: 30vh;
        padding: 10px;
        
        .addressContainer{

            .addressRow{
                font-size: 2vw;
    
                .country{
                    width: 2vw;
                }
            }
        }
    }
}