.container{
    width: 95%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0 auto;

    .title{
        width: 20%;
        height: 5%;
        background-color: #495464;
        font-size: 2vw;
        font-weight: bold;
        font-family: 'Titillium Web',sans-serif;
        color: #EEEEEE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        margin-top: 20px;
    }

    .row{
        width: 70%;
        height: 15vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

        .icon{
            width: 20%;
            font-size: 2vw;
            font-family: 'DynaPuff', cursive;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .makeCall{
                font-size: 2vw;
                font-family: 'DynaPuff', cursive;
                cursor: pointer;
                text-decoration: none;
                color: black,
            }
        }


        .iconLogo{
            width: 3vw;
        }

        .url{
            width: 60%;
            font-size: 1.3vw;
            font-family: 'DynaPuff', cursive;
            color: #495464;
        }
    }

    .toast{
        font-size: 1.3vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 480px){
    .container{
        height: 70vh;
        margin: 0 auto;

        .title{
            width: 30%;
            height: 3%;
            font-size: 3vw;
            margin-top: 10px;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
        }
        .row{
            width: 100%;
            height: 10vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .icon{
                width: 15%;
                font-size: 3vw;
                font-family: 'DynaPuff', cursive;

                .makeCall{
                    font-size: 3vw;
                    font-family: 'DynaPuff', cursive;
                    cursor: pointer;
                    text-decoration: none;
                    color: black,
                }
            }

            .iconLogo{
                width: 30%;
            }
    
            .url{
                width: 60%;
                font-size: 2.3vw;
                font-family: 'DynaPuff', cursive;
                color: #495464;
            }
        }
        .toast{
            font-size: 2vw;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (min-width: 540px) and (max-width: 780px){
    .container{
        height: 100vh;
        margin: 0 auto;
        background-color: #EEEEEE;
        width: 100%;
        padding-left: 2.5%;

        .title{
            width: 30%;
            height: 3.5%;
            font-size: 3vw;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
        }
        .row{
            width: 100%;
            height: 15vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .icon{
                width: 15%;
                font-size: 3vw;
                font-family: 'DynaPuff', cursive;

                .makeCall{
                    font-size: 3vw;
                }
            }

            .iconLogo{
                width: 30%;
            }
    
            .url{
                width: 60%;
                font-size: 2.3vw;
                font-family: 'DynaPuff', cursive;
                color: #495464;
            }
        }
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){
    .container{
        height: 100vh;
        margin: 0 auto;
        width: 100%;
        background-color: #EEEEEE;
        padding-left: 2.5%;

        .title{
            width: 30%;
            // height: 17%;
            font-size: 3vw;
            margin-top: 35px;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
        }
        .row{
            width: 100%;
            height: 15vh;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .icon{
                width: 15%;
                font-size: 3vw;
                font-family: 'DynaPuff', cursive;

                .makeCall{
                    font-size: 3vw;
                }
            }

            .iconLogo{
                width: 30%;
            }
    
            .url{
                width: 60%;
                font-size: 2.3vw;
                font-family: 'DynaPuff', cursive;
                color: #495464;
            }
        }
    }
}