* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.profileBody {
  width: 95%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.profileBody .bannerContainer {
  width: 70%;
  margin: 0 auto;
}
.profileBody .bannerContainer .banner {
  width: 100%;
  padding: 1em;
  border-radius: 2em;
}
.profileBody .qualification {
  width: 100%;
  height: 30%;
  margin-top: 20px;
}
.profileBody .qualification .qualificationTitle {
  width: 20%;
  height: 17%;
  background-color: #495464;
  font-family: "Titillium Web", sans-serif;
  color: #EEEEEE;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.profileBody .qualification ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.profileBody .qualification ul .qualificationText {
  width: 100%;
  height: 25%;
  font-size: 1.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
  padding: 15px;
  display: flex;
  align-items: center;
}
.profileBody .skills {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
}
.profileBody .skills .skillTitle {
  width: 20%;
  height: 13%;
  font-size: 2vw;
  background-color: #495464;
  font-family: "Titillium Web", sans-serif;
  color: #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}
.profileBody .skills .skillContainer {
  width: 100%;
  height: 80%;
  margin: 0 auto;
}
.profileBody .skills .skillContainer .skillCol {
  width: 100%;
  height: 100%;
}
.profileBody .skills .skillContainer .skillCol ul {
  width: 100%;
  height: 100%;
}
.profileBody .skills .skillContainer .skillCol ul .skillText {
  width: 100%;
  height: 25%;
  font-size: 1.5vw;
  font-family: "DynaPuff", cursive;
  color: #495464;
  padding: 15px;
  display: flex;
  align-items: center;
}
.profileBody .skills .skillContainer .skillCol ul .skillText .skillIcon {
  width: 2vw;
}
.profileBody .motivation {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileBody .motivation .motivationText {
  width: 100%;
  height: 100%;
  font-size: 1.5vw;
  font-family: "Silkscreen", cursive;
  color: #2C3333;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

/* mobile iphone */
@media screen and (max-width: 480px) {
  .profileBody {
    width: 95%;
    height: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .profileBody .bannerContainer {
    width: 90%;
  }
  .profileBody .qualification {
    width: 100%;
    height: 30%;
    margin-top: 10px;
  }
  .profileBody .qualification .qualificationTitle {
    width: 30%;
    height: 17%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .qualification ul {
    width: 100%;
    height: 100%;
  }
  .profileBody .qualification ul .qualificationText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .skills {
    width: 100%;
    height: 40%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .profileBody .skills .skillTitle {
    width: 30%;
    height: 13%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .skills .skillContainer {
    width: 100%;
    height: 100%;
  }
  .profileBody .skills .skillContainer .skillCol {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .profileBody .skills .skillContainer .skillCol ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText::before {
    list-style-type: none;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .motivation {
    width: 100%;
    height: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .profileBody .motivation .motivationText {
    width: 75%;
    height: 100%;
    margin-top: 10px;
    font-size: 2.2vw;
    font-family: "Silkscreen", cursive;
    color: #2C3333;
  }
}
/* ipad mini ipad tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
  .profileBody {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #EEEEEE;
    padding-left: 2.5%;
  }
  .profileBody .bannerContainer {
    width: 80%;
  }
  .profileBody .qualification {
    width: 100%;
    height: 30%;
    margin-top: 10px;
  }
  .profileBody .qualification .qualificationTitle {
    width: 30%;
    height: 17%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .qualification ul {
    width: 100%;
    height: 100%;
  }
  .profileBody .qualification ul .qualificationText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .skills {
    width: 100%;
    height: 40%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .profileBody .skills .skillTitle {
    width: 30%;
    height: 13%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .skills .skillContainer {
    width: 100%;
    height: 100%;
  }
  .profileBody .skills .skillContainer .skillCol {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .profileBody .skills .skillContainer .skillCol ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText::before {
    list-style-type: none;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .motivation {
    width: 100%;
    height: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .profileBody .motivation .motivationText {
    width: 75%;
    height: 100%;
    margin-top: 10px;
    font-size: 2.2vw;
    font-family: "Silkscreen", cursive;
    color: #2C3333;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .profileBody {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #EEEEEE;
    padding-left: 2.5%;
  }
  .profileBody .qualification {
    width: 100%;
    height: 40%;
    margin-top: 10px;
  }
  .profileBody .qualification .qualificationTitle {
    width: 30%;
    height: 17%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .qualification ul {
    width: 100%;
    height: 100%;
  }
  .profileBody .qualification ul .qualificationText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .skills {
    width: 100%;
    height: 40%;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .profileBody .skills .skillTitle {
    width: 30%;
    height: 13%;
    font-size: 3vw;
    background-color: #495464;
    font-family: "Titillium Web", sans-serif;
    color: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  .profileBody .skills .skillContainer {
    width: 100%;
    height: 100%;
  }
  .profileBody .skills .skillContainer .skillCol {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .profileBody .skills .skillContainer .skillCol ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText::before {
    list-style-type: none;
  }
  .profileBody .skills .skillContainer .skillCol ul .skillText {
    width: 100%;
    height: 25%;
    margin-top: 10px;
    font-size: 2.5vw;
    font-family: "DynaPuff", cursive;
    color: #495464;
  }
  .profileBody .motivation {
    width: 100%;
    height: 20%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .profileBody .motivation .motivationText {
    width: 75%;
    height: 100%;
    margin-top: 10px;
    font-size: 2.2vw;
    font-family: "Silkscreen", cursive;
    color: #2C3333;
  }
}/*# sourceMappingURL=Home.module.css.map */