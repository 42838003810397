.container{
    width: 95%;
    margin: 0 auto;
    height: 90vh;
    display: flex;
    flex-direction: column;

    .content{
        width: 100%;

        .title{
            width: 20%;
            height: 20%;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            font-size: 2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            font-weight: bold;
        }

        .ul{
            width: 100%;
            height: 100%;
            flex-direction: column;

            .li{
                width: 100%;
                font-size: 1.3vw;
                font-family: 'DynaPuff', cursive;
                color: #495464;
                padding: 15px;
                display: flex;
                align-items: center;

                .liIcon{
                    font-size: 1.3vw;
                    text-align: center;
                    color: #277BC0;
                }
            }
        }
    }

    .serviceText{
        width: 100%;
        height: 30%;
        font-size: 1.5vw;
        font-family: 'Silkscreen', cursive;
        color: #2C3333;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;
    }

    .bannerContainer{
        display: none;
    }
}

@media screen and (max-width: 480px){
    .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 90vh;

        .content{
            width: 100%;
            margin-left: 10px;
    
            .title{
                width: 30%;
                height: 10%;
                font-size: 3vw;
            }
    
            .ul{
                width: 100%;
                height: 50%;
    
                .li{
                    width: 100%;
                    // height: 25%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
    
                    .liIcon{
                        font-size: 5vw;
                        text-align: center;
                        color: #277BC0;
                    }

                    .icon{
                        width: 2.5vw;
                    }
                }
            }
        }
    
        .serviceText{
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.2vw;
            font-family: 'Silkscreen', cursive;
            color: #2C3333;
        }

        .bannerContainer{
            display: flex;
            width: 100vw;
            height: 28vh;

            .banner{
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 540px) and (max-width: 780px){
    .container{
        display: flex;
        flex-direction: column;

        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 30vh;
    
            .title{
                width: 30%;
                height: 17%;
                font-size: 3vw;
            }
    
            .ul{
                width: 100%;
                height: 50%;
                flex-direction: column;
    
                .li{
                    width: 100%;
                    height: 50%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
    
                    .liIcon{
                        font-size: 2.3vw;
                        text-align: center;
                        color: #277BC0;
                    }
                }
            }
        }
    
        .serviceText{
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.2vw;
            font-family: 'Silkscreen', cursive;
            color: #2C3333;
        }
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){
    .container{
        display: flex;
        flex-direction: column;

        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 100%;
    
            .title{
                width: 30%;
                height: 13%;
                font-size: 3vw;
            }
    
            .ul{
                width: 100%;
                height: 50%;
                flex-direction: column;
    
                .li{
                    width: 100%;
                    height: 50%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
    
                    .liIcon{
                        font-size: 2.3vw;
                        text-align: center;
                        color: #277BC0;
                    }
                }
            }
        }
    
        .serviceText{
            width: 100%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.2vw;
            font-family: 'Silkscreen', cursive;
            color: #2C3333;
        }
    }
}