@import url('https://fonts.googleapis.com/css2?family=DynaPuff&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DynaPuff&family=Libre+Franklin:ital,wght@1,100&family=Titillium+Web:ital,wght@0,200;1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DynaPuff&family=Libre+Franklin:ital,wght@1,100&family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=DynaPuff&family=Libre+Franklin:ital,wght@1,100&family=Silkscreen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300&family=Poppins:wght@100&family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-size: 1.6rem; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* hide scrollable scrollbar on firefox */
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-width: none;
  }
}

@media screen and (max-width: 480px){
  body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
