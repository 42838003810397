* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: space-around;
  position: relative;
}
.container .title {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
}
.container .title .goBack {
  width: 10%;
  font-size: 2.5vw;
  cursor: pointer;
}
.container .title .titleText {
  width: 80%;
  background-color: #495464;
  border-radius: 5vw;
  font-size: 2.5vw;
  font-family: "Titillium Web", sans-serif;
  color: #EEEEEE;
  padding-left: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}
.container .slideShow {
  width: 80%;
  height: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  margin: 0 auto;
  margin-top: 10px;
}
.container .slideShow .imageContainer {
  width: 60%;
  height: 90%;
  margin: 0 auto;
}
.container .slideShow .imageContainer .image {
  width: 100%;
}
.container .contextContainer {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.container .contextContainer .language {
  width: 100%;
  height: 30%;
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
  color: #495464;
  font-size: 1.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.5px;
}
.container .contextContainer .description {
  width: 100%;
  height: 70%;
  font-family: "Roboto Condensed", sans-serif;
  color: #495464;
  font-size: 1.7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 480px) {
  .container {
    height: 55vh;
  }
  .container .title {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
  }
  .container .title .goBack {
    font-size: 4vw;
  }
  .container .title .titleText {
    font-size: 4vw;
  }
  .container .slideShow .imageContainer {
    width: 90%;
  }
  .container .slideShow .imageContainer .image {
    width: 100%;
  }
  .container .contextContainer .language {
    font-size: 3vw;
    text-align: center;
  }
  .container .contextContainer .description {
    font-size: 3vw;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .container .slideShow {
    width: 90%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .container {
    background-color: #EEEEEE;
  }
  .container .title {
    height: 10%;
  }
  .container .slideShow {
    width: 90%;
    height: 50%;
  }
  .container .contextContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .container .contextContainer .language {
    margin-top: 8vh;
  }
}/*# sourceMappingURL=seeMore.module.css.map */