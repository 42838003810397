*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container{
    width: 100vw;
    height: 100vh;
    background-color: #16213E;
    position: absolute;
    z-index: 100;

    .loading{
        width: 0;
        height: 2vh;
        margin: 0 auto;
        border-radius: 5vw;
        border: 5px solid #EEEEEE;
        background-color: #94B49F;
        position: relative;
        margin-top: 2vh;
        animation: loading 4s linear infinite alternate;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading::after{
        content: "Welcome";
        font-family: 'Exo 2', sans-serif;
        color: #EEEEEE;
        margin-top: 5vw;
        font-size: 1.5vw;
    }
    
    @keyframes loading{
        0%{
            width: 0%;
        }
        25%{
            width: 50%;
        }
        50%{
            width: 75%;
        }
        75%{
            width: 85%;
        }
        100%{
            width: 90%;
        }
    }

    .textContainer{
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .helloText{
            width: 100%;
            height: 30%;
            margin: 0 auto;
            font-size: 5vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-family: 'Exo 2', sans-serif;
            font-weight: bold;
            color: #94B49F;
            animation: changeColor 3s linear infinite alternate-reverse;
        }

        @keyframes changeColor {
            0%{
                opacity: 1;
                color: #94B49F
            }
            50%{
                opacity: 0.5;
                color: #E4DCCF
            }
            100%{
                opacity: 0;
                color: #377D71
            }
        }

        .wrapper{
            display: inline-flex;

            .animatedStaticText{
                font-size: 3vw;
                font-family: 'Exo 2', sans-serif;
                font-weight: bold;
                color: #EEEEEE;
                text-transform: uppercase;
                margin-right: 1vw;
                height: 50px;
                line-height: 50px;
            }

            .animatedLi{
                list-style-type: none;
                font-size: 3vw;
                font-family: 'Exo 2', sans-serif;
                font-weight: bold;
                color: #3D3C42;
                text-transform: uppercase;
                height: 50px;
                line-height: 50px;
                overflow: hidden;

                li{
                    top: 0;
                    position: relative;
                    animation: slide 9s steps(3) infinite;
                    text-align: center;
                    height: 50px;
                    overflow: hidden;
    
                    @keyframes slide {
                        100%{
                            top: -150px;
                        }
                    }
                    
                    span{
                        position: relative;
                        color: #A5C9CA;
                    }
    
                    span::after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 18vh;
                        left: 0;
                        border-left: 2px solid #EEEEEE;
                        background: #16213E;
                        animation: typing 3s steps(22) infinite;
                        overflow: hidden;
                    }
    
                    @keyframes typing {
                        100%{
                            left: 100%;
                            margin: 0 -35px 0 35px;
                        }
                    }
                }

            }
        }
        
    }

    .btnContainer{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select:none;
        -moz-user-select:none;
        user-select:none;

        .btn{
            width: 25vw;
            height: 5vh;
            background-color: #3F4E4F;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1vw;
            font-size: 1.5vw;
            font-family: 'Exo 2', sans-serif;
            color: #EEEEEE;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    .copyRight{
        width: 30%;
        height: 5%;
        color: #EEEEEE;
        font-family: 'Exo 2', sans-serif;
        margin-left: 5%;
        font-size: 1.3vw;
        display: flex;
        align-items: center;
    }
}


@media screen and (max-width: 480px){
    .container{
        
        .loading{
            height: 1.8vh;
        }
    
        .loading::after{
            margin-top: 10vw;
            font-size: 3vw;
        }
        .textContainer{
    
            .helloText{
                font-size: 12vw;
            }
    
            .wrapper{
    
                .animatedStaticText{
                    font-size: 7vw;
                    height: 50px;
                    line-height: 50px;
                }
    
                .animatedLi{
                    font-size: 7vw;
                    height: 49px;
                    line-height: 50px;
    
                    li{
        
                        span::after{
                            height: 50px;
                        }
                    }
    
                }
            }
            
        }
    
        .btnContainer{
    
            .btn{
                width: 40%;
                height: 50%;
                font-size: 2.5vw;
            }
        }
    
        .copyRight{
            width: 50%;
            font-size: 2vw;
        }
    }
}

@media screen and (min-width: 540px) and (max-width: 780px){
    .container{
        .loading{
            height: 1.8vh;
        }
    
        .loading::after{
            margin-top: 10vw;
            font-size: 3vw;
        }
    
        .textContainer{
    
            .helloText{
                font-size: 8vw;
            }
    
            .wrapper{
    
                .animatedStaticText{
                    font-size: 5vw;
                    height: 50px;
                    line-height: 50px;
                }
    
                .animatedLi{
                    font-size: 5vw;
                    height: 50px;
                    line-height: 50px;
    
                    li{
                        span::after{
                            height: 100px;
                        }
                    }
    
                }
            }
            
        }
    
        .btnContainer{
    
            .btn{
                width: 40%;
                height: 50%;
                font-size: 2vw;
            }
        }
    
        .copyRight{
            width: 50%;
            font-size: 2vw;
        }
    }
    
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){

    .container{
        .loading{
            height: 1.8vh;
        }
    
        .loading::after{
            margin-top: 10vw;
            font-size: 3vw;
        }
        .textContainer{
    
            .helloText{
                font-size: 8vw;
            }
    
            .wrapper{
    
                .animatedStaticText{
                    font-size: 4.5vw;
                    height: 50px;
                    line-height: 50px;
                }
    
                .animatedLi{
                    font-size: 4.5vw;
                    height: 50px;
                    line-height: 50px;
    
                    li{
        
                        span::after{
                            height: 100px;
                        }
                    }
    
                }
            }
            
        }
    
        .btnContainer{
    
            .btn{
                width: 40%;
                height: 50%;
                font-size: 2vw;
            }
        }
    
        .copyRight{
            width: 50%;
            font-size: 2vw;
        }
    }
  }

// @media screen and (min-width: 1280px) {
// //For Big TV's (HD Screens) 
//     .container{
//         .loading{
//             height: 1.6vh;
//         }

//         .loading::after{
//             margin-top: 10vw;
//             font-size: 3vw;
//         }
//         .textContainer{
//             height: 80%;
//             .helloText{
//                 font-size: 6vw;
//             }

//             .wrapper{
//                 height: 100%;
//                 .animatedStaticText{
//                     font-size: 4.5vw;
//                     height: 50px;
//                     line-height: 50px;
//                 }

//                 .animatedLi{
//                     font-size: 4.5vw;
//                     height: 150px;
//                     line-height: 150px;

//                     li{
//                         height: 50px;
//                         background-color: red;
//                         @keyframes slide {
//                             100%{
//                                 top: -250px;
//                             }
//                         }

//                         span::after{
//                             height: 50vh;
//                         }
//                     }

//                 }
//             }
            
//         }

//         .btnContainer{

//             .btn{
//                 width: 30%;
//                 height: 50%;
//                 font-size: 2vw;
//             }
//         }

//         .copyRight{
//             width: 50%;
//             font-size: 1.5vw;
//         }
//     }
// }


// @media screen and (min-width: 1920px) {
// //For Projectors or Higher Resolution Screens (Full HD)
//     .container {
//         width: 1890px;
//     }
// }
// @media screen and (min-width: 3840px) {
// //For 4K Displays (Ultra HD)
//     .container {
//         width: 3810px;
//     }
// }