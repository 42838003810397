* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Dosis", sans-serif;
}

.container {
  width: 100%;
  height: 100vh;
  background-color: #EEEEEE;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 100;
  position: absolute;
}
.container .imageContainer {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container .imageContainer .textContainer {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container .imageContainer .textContainer .textTitle {
  font-size: 2.5vw;
  color: #16213E;
  text-transform: uppercase;
}
.container .imageContainer .textContainer .text {
  font-size: 1.5vw;
}
.container .imageContainer .image {
  width: 70%;
}
.container .form {
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 2vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.container .form .formTextContainer {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 2vw;
  border-style: dotted;
}
.container .form .formTextContainer .formText {
  font-size: 1.5vw;
  color: #16213E;
}
.container .form .formTextContainer .logo {
  width: 10%;
  border-radius: 50%;
}
.container .form .input {
  width: 70%;
  height: 6vh;
  border-radius: 0.5vw;
  border: none;
  padding-left: 1vw;
  font-size: 1.5vw;
}
.container .form .inputMessage {
  width: 70%;
  height: 20vh;
  border-radius: 0.5vw;
  border: none;
  padding-left: 1vw;
  font-size: 1.5vw;
  resize: none;
}
.container .form .button {
  width: 70%;
  height: 6.5vh;
  background-color: #96E5D1;
  border-radius: 1vw;
  text-transform: uppercase;
  font-size: 1.5vw;
  color: #16213E;
  border: none;
  cursor: pointer;
  box-shadow: 1px 2px 2px 1px rgba(136, 133, 133, 0.336);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .toastText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3vw;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
  }
  .container .imageContainer {
    width: 100%;
    height: 50%;
  }
  .container .imageContainer .textContainer {
    width: 100%;
    height: 50%;
  }
  .container .imageContainer .textContainer .textTitle {
    font-size: 5vw;
    color: #16213E;
    text-transform: uppercase;
  }
  .container .imageContainer .textContainer .text {
    font-size: 3vw;
  }
  .container .imageContainer .image {
    width: 50%;
  }
  .container .form {
    width: 80%;
    height: 50%;
    gap: 1vh;
  }
  .container .form .formTextContainer {
    width: 90%;
    border-radius: 2vw;
  }
  .container .form .formTextContainer .formText {
    font-size: 3vw;
    color: #16213E;
  }
  .container .form .formTextContainer .logo {
    width: 15%;
  }
  .container .form .input {
    width: 90%;
    height: 5vh;
    padding-left: 1.5vw;
    font-size: 3vw;
  }
  .container .form .inputMessage {
    width: 90%;
    height: 20vh;
    padding-left: 1.5vw;
    font-size: 3vw;
  }
  .container .form .button {
    width: 90%;
    height: 6vh;
    border-radius: 1vw;
    font-size: 4vw;
  }
  .container .toastText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .container {
    width: 100%;
    height: 100vh;
    background-color: #EEEEEE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 100;
    position: absolute;
  }
  .container .imageContainer {
    width: 50%;
    height: 50%;
    justify-content: space-around;
  }
  .container .imageContainer .textContainer {
    width: 100%;
    height: 50%;
  }
  .container .imageContainer .textContainer .textTitle {
    font-size: 4vw;
    color: #16213E;
    text-transform: uppercase;
  }
  .container .imageContainer .textContainer .text {
    font-size: 2.5vw;
  }
  .container .imageContainer .image {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .container .form .formTextContainer {
    width: 90%;
  }
  .container .form .formTextContainer .formText {
    font-size: 2vw;
    color: #16213E;
  }
  .container .form .formTextContainer .logo {
    width: 15%;
  }
  .container .form .input {
    width: 90%;
    border-radius: 1vw;
    padding-left: 2vw;
    font-size: 2vw;
  }
  .container .form .inputMessage {
    width: 90%;
    height: 20vh;
    border-radius: 1vw;
    padding-left: 2vw;
    font-size: 2vw;
  }
  .container .form .button {
    width: 90%;
    height: 5vh;
    font-size: 2.5vw;
  }
  .container .toastText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw;
  }
}/*# sourceMappingURL=Form.module.css.map */