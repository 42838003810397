*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.container{
    width: 95vw;
    margin: 0 auto;
    height: 100%;
    
    .contentContainer{
        width: 100%;
        // height: 30vh;
        margin-bottom: 5vw;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .carouselContainer{
            width: 60%;
            height: 60%;
            margin: 0 auto;
            background-color: #EEEEEE;
            border-radius: 2.5vw;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            

            .imageContainer{
                width: 90%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .image{
                    width: 100%;
                    border-radius: 0.5vw;
                    box-shadow: 1px 2px 2px 1px rgb(184, 181, 181);
                }
            }
        }

        .detailContainer{
            width: 50%;
            height: 40%;
            margin: 0 auto;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            .detailText{
                font-size: 2.5vw;
                font-family: 'Titillium Web',sans-serif;
                color: #495464;
            }
        }
    }

    .seeMore{
        width: 20vw;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #293462;
        margin: 0 auto;
        cursor: pointer;
        margin-top: 2vw;
        border-radius: 0.7vw;

        
        .seeMoreText{
            font-size: 1.5vw;
            font-family: 'Titillium Web',sans-serif;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EEEEEE;
            -webkit-user-select:none;
            -moz-user-select:none;
            user-select:none;
        }
    }

}
@media screen and (max-width: 480px){
    .container{
        height: 90vh;
        overflow-y: scroll;
        
        .contentContainer{
    
            .carouselContainer{
                width: 100%;
    
                .imageContainer{
    
                    .image{
                        width: 100%;
                        border-radius: 1vw;
                    }
                }
            }
    
            .detailContainer{
    
                .detailText{
                    font-size: 4vw;
                    text-align: center;
                }
            }
        }
    
        .seeMore{
            width: 50vw;
            height: 4vh;
    
            
            .seeMoreText{
                font-size: 3vw;
            }
        }

    }
}

@media screen and (min-width: 540px) and (max-width: 780px){
    .container{
        height: 90vh;
        overflow-y: scroll;
        
        .contentContainer{
    
            .carouselContainer{
                width: 80%;
                
    
                .imageContainer{
    
                    .image{
                        border-radius: 1vw;
                    }
                }
            }
    
            .detailContainer{
                width: 100%;
    
                .detailText{
                    font-size: 3vw;
                }
            }
        }
    
        .seeMore{
            width: 30vw;
    
            
            .seeMoreText{
                font-size: 2vw;
            }
        }
    
    }
}

@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){
    .container{
        height: 90vh;
        overflow-y: scroll;
        
        .contentContainer{

            .carouselContainer{
                width: 80%;

                .imageContainer{
                    width: 100%;
    
                    .image{
                        border-radius: 1vw;
                    }
                }
            }
    
            .detailContainer{
    
                .detailText{
                    font-size: 2.5vw;
                }
            }
        }
    
        .seeMore{
            width: 30vw;
    
            
            .seeMoreText{
                font-size: 2vw;
            }
        }
    
    }
}