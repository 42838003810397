* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #EEEEEE;
}

@media screen and (max-width: 480px) {
  .mainContainer {
    width: 100vw;
    overflow: hidden;
    height: 100%;
  }
}
@media screen and (min-width: 540px) and (max-width: 780px) {
  .mainContainer {
    height: 100vh;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .mainContainer {
    height: 100vh;
  }
}/*# sourceMappingURL=container.module.css.map */