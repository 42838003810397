*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.profileBody{
    width: 95%;
    // height: 100vh;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .bannerContainer{
        width: 70%;
        margin: 0 auto;

        .banner{
            width: 100%;
            padding: 1em;
            border-radius: 2em;
        }
    }

    .qualification{
        width: 100%;
        height: 30%;
        margin-top: 20px;
        
        .qualificationTitle{
            width: 20%;
            // width: 15rem;
            height: 17%;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            // font-size: 2rem;
            font-size: 2vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
        }

        ul{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .qualificationText{
                width: 100%;
                height: 25%;
                // margin-top: 30px;
                font-size: 1.5vw;
                // font-size: 1em;
                font-family: 'DynaPuff', cursive;
                color: #495464;
                padding: 15px;
                display: flex;
                align-items: center;
            }
        }

    }

    .skills{
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;

        .skillTitle{
            width: 20%;
            // width: 15rem;
            height: 13%;
            font-size: 2vw;
            // font-size: 2rem;
            background-color: #495464;
            font-family: 'Titillium Web',sans-serif;
            color: #EEEEEE;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
        }
        .skillContainer{
            width: 100%;
            height: 80%;
            margin: 0 auto;

            .skillCol{
                width: 100%;
                height: 100%;

                ul{
                    width: 100%;
                    height: 100%;

                    .skillText{
                        width: 100%;
                        height: 25%;
                        font-size: 1.5vw;
                        // font-size: 1rem;
                        font-family: 'DynaPuff', cursive;
                        color: #495464;
                        padding: 15px;
                        display: flex;
                        align-items: center;

                        .skillIcon{
                            width: 2vw;
                            // width: 1rem;
                        }
                    }
                }
            }

        }
    }

    .motivation{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .motivationText{
            width: 100%;
            height: 100%;
            font-size: 1.5vw;
            // font-size: 1rem;
            font-family: 'Silkscreen', cursive;
            color: #2C3333;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 2rem;
        }
    }
}
/* mobile iphone */
@media screen and (max-width: 480px) {

    .profileBody{
        width: 95%;
        height: 65%;
        // margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .bannerContainer{
            width: 90%;
        }

        .qualification{
            width: 100%;
            height: 30%;
            margin-top: 10px;
            
            .qualificationTitle{
                width: 30%;
                height: 17%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }

            ul{
                width: 100%;
                height: 100%;
                
                
                .qualificationText{
                    width: 100%;
                    height: 25%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
                }
            }

        }

        .skills{
            width: 100%;
            height: 40%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .skillTitle{
                width: 30%;
                height: 13%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }
            .skillContainer{
                width: 100%;
                height: 100%;

                .skillCol{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    ul{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
        
                        .skillText::before{
                            list-style-type: none;
                        }
                        .skillText{
                            width: 100%;
                            height: 25%;
                            margin-top: 10px;
                            font-size: 2.5vw;
                            font-family: 'DynaPuff', cursive;
                            color: #495464;
                        }
                    }
                }

            }
        }

        .motivation{
            width: 100%;
            height: 20%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .motivationText{
                width: 75%;
                height: 100%;
                margin-top: 10px;
                font-size: 2.2vw;
                font-family: 'Silkscreen', cursive;
                color: #2C3333;
            }
        }
    }

}
/* ipad mini ipad tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .profileBody{
        width: 100%;
        // height: 65%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #EEEEEE;
        padding-left: 2.5%;

        .bannerContainer{
            width: 80%;
        }

        .qualification{
            width: 100%;
            height: 30%;
            margin-top: 10px;
            
            .qualificationTitle{
                width: 30%;
                height: 17%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }

            ul{
                width: 100%;
                height: 100%;
                
                
                .qualificationText{
                    width: 100%;
                    height: 25%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
                }
            }

        }

        .skills{
            width: 100%;
            height: 40%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .skillTitle{
                width: 30%;
                height: 13%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }
            .skillContainer{
                width: 100%;
                height: 100%;

                .skillCol{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    ul{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
        
                        .skillText::before{
                            list-style-type: none;
                        }
                        .skillText{
                            width: 100%;
                            height: 25%;
                            margin-top: 10px;
                            font-size: 2.5vw;
                            font-family: 'DynaPuff', cursive;
                            color: #495464;
                        }
                    }
                }

            }
        }

        .motivation{
            width: 100%;
            height: 20%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .motivationText{
                width: 75%;
                height: 100%;
                margin-top: 10px;
                font-size: 2.2vw;
                font-family: 'Silkscreen', cursive;
                color: #2C3333;
            }
        }
    }
}
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5){
    .profileBody{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #EEEEEE;
        padding-left: 2.5%;

        .qualification{
            width: 100%;
            height: 40%;
            margin-top: 10px;
            
            .qualificationTitle{
                width: 30%;
                height: 17%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }

            ul{
                width: 100%;
                height: 100%;
                
                
                .qualificationText{
                    width: 100%;
                    height: 25%;
                    margin-top: 10px;
                    font-size: 2.5vw;
                    font-family: 'DynaPuff', cursive;
                    color: #495464;
                }
            }

        }

        .skills{
            width: 100%;
            height: 40%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .skillTitle{
                width: 30%;
                height: 13%;
                font-size: 3vw;
                background-color: #495464;
                font-family: 'Titillium Web',sans-serif;
                color: #EEEEEE;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
            }
            .skillContainer{
                width: 100%;
                height: 100%;

                .skillCol{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    ul{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
        
                        .skillText::before{
                            list-style-type: none;
                        }
                        .skillText{
                            width: 100%;
                            height: 25%;
                            margin-top: 10px;
                            font-size: 2.5vw;
                            font-family: 'DynaPuff', cursive;
                            color: #495464;
                        }
                    }
                }

            }
        }

        .motivation{
            width: 100%;
            height: 20%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            .motivationText{
                width: 75%;
                height: 100%;
                margin-top: 10px;
                font-size: 2.2vw;
                font-family: 'Silkscreen', cursive;
                color: #2C3333;
            }
        }
    }
}